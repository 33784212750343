<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="订单编号">
              <a-input v-model="queryParam.order_no" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="供应商">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择供应商"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="创建时间">
              <a-range-picker @change="onDateChange"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="费用状态">
              <a-select
                v-model="queryParam.fee_status"
                showSearch
                allowClear
                :filter-option="filterOption"
                style="width: 100%"
              >
                <a-select-option v-for="o in this.GLOBAL.feeStatusMaps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <!-- <a-button type="primary" :disabled="!selectedRowKeys.length" @click="handleApplyBizAudit()">商务审核</a-button> -->
      <!-- <a-button type="primary" @click="handleExport">导出</a-button> -->
    </div>
    <s-table
      ref="table"
      size="default"
      :rowKey="(record, index) => {
        return record.serial_num
      }"
      :columns="columns"
      :data="loadData"
      :scroll="{ x: 'max-content' }"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange, onSelect: onSelect }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="payInfoSlots" slot-scope="text, record">
        <span>
          <a-tag :color="record.is_advance === 1 ? 'green' : 'red'" style="margin-bottom:2px;">
            {{ record.is_advance === 1 ? '后付' : '预付' }}
          </a-tag>
        </span>
        <span>{{ paymentMap[record.pay_method] ? paymentMap[record.pay_method].name : '' }}</span>
      </div>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="printPayFeeBill(record)">付款单</a>
          <a-divider type="vertical" />
          <a @click="handleDeletePayment(record)">删除</a>
          <a-divider type="vertical" v-if="!record.invoice_nos"/>
          <a v-if="!record.invoice_nos" @click="handleBindInvoice(record)" style="margin-right:10px">关联发票号</a>
        </template>
      </span>
      <div slot="feeItem" slot-scope="text, record">
        <div v-for="item in record.fee_infos" :key="item.id">
          <a-tag :color="feeStatusColor(item.fee_status)" style="margin-bottom:2px;">
            {{ GLOBAL.feeStatusMaps[item.fee_status] ? GLOBAL.feeStatusMaps[item.fee_status].name : '' }}
          </a-tag>
          <span>{{ item.fee_name }}:{{ item.amount }}</span>
        </div>
      </div>
      <div slot="contractlistItem" slot-scope="text">
        <div v-for="item in text" :key="item.id">
          <span>{{ item.contract_no }}</span>
        </div>
      </div>
    </s-table>
    <a-modal
      :visible="applyFormVisible" :confirmLoading="confirmLoading"
      title="商务审核申请" :width="720"
      @cancel="applyFormVisible = false"
      @ok="handleApplyFormSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-divider orientation="left">基础信息</a-divider>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="付款方式" prop="pay_method">
              <a-select v-model="form.pay_method">
                <a-select-option :value="item.value" v-for="item in paymentList" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="附件数" prop="appendix_num">
              <a-input suffix="张" v-model="form.appendix_num"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="发票号码" prop="invoice_number">
          <a-select
            showSearch
            mode="multiple"
            ref="invoice_number"
            :filter-option="filterOption"
            allowClear
            placeholder="请选择发票号码"
            style="width: 100%"
            v-model="form.invoice_num"
          >
            <a-select-option v-for="op in invoiceRegOps" :key="op.id"
            >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
        <!--        <a-form-model-item label="发票号">-->
        <!--          <a-input v-model="form.invoice_num" />-->
        <!--        </a-form-model-item>-->
        <a-divider orientation="left">费用信息
          <a-tag color="#f50" style="margin-left:10px;">合计:{{ totalAmount }}</a-tag>
        </a-divider>
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
          style="padding-bottom:10px;"
        >
          全选
        </a-checkbox>
        <a-row>
          <a-checkbox-group v-model="checkedList" @change="onChange" style="width:100%;">
            <a-row v-for="item in feeOrderList" :key="item.id">
              <a-alert :message="`订单编号:${item.orderId}`" type="info" show-icon style="margin-bottom:10px;" />
              <a-col :span="8" v-for="fee in item.feeList" :key="fee.id" style="padding-left:15px;padding-bottom:10px;">
                <a-checkbox :value="fee.id"> {{ fee.pay_target_name }}{{ fee.feeName }}:{{ fee.money }}{{ fee.currency_name }} </a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-row>
        <a-form-model-item label="申请备注" prop="apply_remark">
          <a-textarea v-model="form.apply_remark"></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal
      :visible="invoiceModel"
      :confirmLoading="invoiceModelLoading"
      :width="720"
      :maskClosable="false"
      title="关联发票"
      @cancel="invoiceModel = false"
      @ok="handleBindInvoiceSubmit"
    >
      <bind-invoice ref="bindInvoice" />
    </a-modal>
    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillResult"
          :orderData="printPayBillRecord"
          :invoiceList="invoiceList"
        >
        </fms-pay-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printPayBillBtn"
                  v-print="printPayBill">确认打印</a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getCommonNoPage, getSupplierAndByName, getSupplier, downloadExcel } from '@/api/common'
import { filterOption } from '@/utils/util'
import { CumsFinanceFunc, exportCumsFinance, getCumsMetaOption } from '@/api/cums'
import { listPagePaymentList, deletePayment } from '@/api/mms'
import moment from 'moment'
import debounce from 'lodash/debounce';
import { fmsBizFinanceFunc, getFmsInfoDetail, getInvoiceDataByAuditId, fmsBizAuditFunc } from '@/api/fms'
import fmsPayBill from '@/views/fms/bill/FmsPayBill';
import bindInvoice from '@/views/components/BindInvoice'
export default {
  components: {
    STable,
    fmsPayBill,
    bindInvoice
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {},
      paymentMap: {
        1: { value: 1, name: '转账' },
        2: { value: 2, name: '支票' },
        3: { value: 3, name: '代付' },
        4: { value: 4, name: '打卡' },
        5: { value: 5, name: '现金' },
        6: { value: 6, name: '内部结算' }
      },
      customerInfo: undefined,
      customerInfos: [],
      fetching: false,
      customerOps: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '付款单号',
          dataIndex: 'serial_num'
        },
        {
          title: '订单编号',
          dataIndex: 'order_nos'
        },
        {
          title: '委托客户',
          dataIndex: 'supplier_name'
        },
        {
          title: '付款信息',
          dataIndex: 'pay_method',
          scopedSlots: { customRender: 'payInfoSlots' }
        },
        {
          title: '费用',
          dataIndex: 'fee_infos',
          scopedSlots: { customRender: 'feeItem' }
        },
        {
          title: '关联合同',
          dataIndex: 'contract_list',
          scopedSlots: { customRender: 'contractlistItem' }
        },
        {
          title: '关联发票',
          dataIndex: 'invoice_nos'
        },
        {
          title: '驳回原因',
          dataIndex: 'deny_reason'
        },
        {
          title: '创建日期',
          dataIndex: 'updated_at',
          customRender: (text) => {
            return text && moment(text).format('YYYY/MM/DD HH:MM:SS')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '200px',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      selectedRows: [],
      applyFormVisible: false,
      confirmLoading: false,
      form: {
        pay_method: '',
        appendix_num: null,
        apply_remark: '',
        invoice_num: []
      },
      rules: {
        apply_remark: [
          { required: true, message: '请输入申请备注', trigger: 'blur' }
        ],
        pay_method: [
          { required: true, message: '请选择付款方式', trigger: 'change' }
        ]
      },
      loadData: parameter => {
        return listPagePaymentList(this.queryParam)
          .then(res => {
            return res
          })
      },
      totalAmount: '',
      indeterminate: false,
      checkAll: true,
      checkedList: [],
      feeOrderList: [],
      paymentList: [
        {
          value: 1, name: '转账'
        },
        {
          value: 2, name: '支票'
        },
        {
          value: 3, name: '代付'
        },
        {
          value: 4, name: '打卡'
        },
        {
          value: 5, name: '现金'
        },
        {
          value: 6, name: '内部结算'
        }
      ],
      choosePayTargetVisible: false,
      choosePayTargetVisibleLodding: false,
      payTarget: [],
      pay_target_Data: {},
      orderData: {},
      userInfo: this.$store.getters.userInfo,
      payBillName: '',
      invoiceRegOps: [],
      supplierOps: [],
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillResult: [],
      printPayBillRecord: {},
      invoiceList: [],
      invoiceModel: false,
      invoiceModelLoading: false,
      audit_id: null
    }
  },
  created() {
    getCumsMetaOption('cums_meta_info', { type: 7 }).then(v => {
      this.customerInfos = v;
    });
    getSupplier({ department: 7 }).then(res => {
      this.supplierOps = res
    })
  },
  methods: {
    onDateChange(date, dateString) {
      if (dateString[0]) {
        this.queryParam['create_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss');
        this.queryParam['create_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.queryParam['create_start_time'] = null;
        this.queryParam['create_end_time'] = null;
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getSupplierAndByName({
        supplierName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value === undefined) {
        // 委托客户选择框中无值
        this.customerInfo = undefined;
        this.queryParam['customer_id'] = null;
      } else {
        // 委托客户选择框中有值
        this.customerInfo = value;
        this.queryParam['customer_id'] = value.key;
      }
      this.customerOps = []
      this.fetching = false
    },
    filterOption,
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },
    onSelect (record, selected, selectedRows) {
      if (selected) {
        this.selectedRows.push(record);
      }

      if (!selected) {
        const delIndex = this.selectedRows.findIndex(val => {
          return val.serial_num === record.serial_num
        })
        this.selectedRows.splice(delIndex, 1);
      }
    },
    handleVerifyAccount(operate) {
      const data = []
      let flag = true
      this.selectedRows.forEach(v => {
        if (operate === 'check') {
          if (v.pay_status === 0) {
            data.push(v.id)
          } else {
            flag = false
          }
        } else {
          if (v.pay_status === 1) {
            data.push(v.id)
          } else {
            flag = false
          }
        }
      })
      if (flag) {
        CumsFinanceFunc(operate, {
          ids: this.selectedRowKeys,
          type: 2
        }, this.queryParam.type).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          })
          this.selectedRowKeys = []
          this.selectedRows = []
          this.$refs.table.refresh(false)
        })
      } else {
        this.$message.warning(operate === 'check' ? '请选择未对账的数据' : '请选择已对账待审核的数据')
      }
    },
    handleAudit(operate) { // 业务审核
      const data = [];
      this.selectedRows.forEach(v => {
        v.fees.forEach(fee => {
          switch (operate) {
            // 审核
            case 'basic_audit':
              if (fee.fee_status === 1) data.push(v.id);
              break;
            // 未审核
            case 'undo_audit':
              if (fee.fee_status === 2) data.push(v.id);
              break;
          }
        });
      });
      if (data.length > 0) {
        CumsFinanceFunc(operate, {
          type: 2,
          ids: data
        }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '操作成功'
          });
          this.selectedRowKeys = [];
          this.selectedRows = [];
          this.$refs.table.refresh(false);
        });
      } else {
        this.$message.warning(operate === 'basic_audit' ? '请选择已对账待审核的数据' : '请选择业务已审核的数据');
      }
    },
    // handleApplyBizAudit() { // 提交商务审核
    //   // 提交商务审核
    //   let flag = false
    //   this.checkedList = []
    //   this.totalAmount = ''
    //   const feeMaps = []
    //   const map = new Map()
    //   this.selectedRows.forEach(v => {
    //     const orderFee = {
    //       orderId: v.serial_num,
    //       feeList: v.fees && v.fees.filter(e => e.fee_status === 2 || e.fee_status === 4).map(e => {
    //           if (map.has(e.currency_name)) {
    //             map.set(e.currency_name, e.money + map.get(e.currency_name))
    //           } else {
    //             map.set(e.currency_name, e.money)
    //           }
    //           if (e.fee_status === 2 || e.fee_status === 4) {
    //             flag = true
    //           }
    //           this.checkedList.push(e.id)
    //           return {
    //             id: e.id,
    //             feeName: e.fee_name,
    //             money: e.money,
    //             currency_name: e.currency_name,
    //             pay_target_name: e.pay_target_name
    //           }
    //         })
    //     }
    //     feeMaps.push(orderFee)
    //   })
    //   if (flag) {
    //     for (const key of map.keys()) {
    //       this.totalAmount += map.get(key).toFixed(2) + key + ','
    //     }
    //     this.feeOrderList = feeMaps
    //     const data = {}
    //     getCommonNoPage('invoice_registration', data).then((v) => {
    //       this.invoiceRegOps = v
    //     })
    //     this.applyFormVisible = true
    //   } else {
    //     this.$message.warning('没有可提交的费用,费用状态必须为业务已审核或商务审核被驳回')
    //   }
    // },
    // 后补发票号
    handleApplyInvoiceNumFormSubmit() {
      this.$refs.invoiceNumForm.validate(valid => {
        if (valid) {
          const data = {
            invoice_num: this.invoiceNumForm.invoiceNum,
            department: 9,
            ids: this.checkedList
          }
          fmsBizFinanceFunc('save_invoice_number', data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.selectedRows = []
            this.selectedRowKeys = []
            this.applyInvoiceNumFormVisible = false
            // this.invoiceNumForm.invoiceNum = null
            // this.$refs.invoiceNumForm.resetFields();
            this.$refs.table.refresh(false)
          })
        }
      })
    },
    handleApplyFormSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          CumsFinanceFunc('business_audit', {
            apply_remark: this.form.apply_remark,
            pay_method: this.form.pay_method,
            invoice_num: this.form.invoice_num,
            appendix_num: this.form.appendix_num,
            id_list: this.checkedList
          }, this.queryParam.type).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
            this.confirmLoading = false
            this.applyFormVisible = false
            this.selectedRowKeys = []
            this.selectedRows = []
            this.form.invoice_num = []
            // this.$refs.form.resetFields();
            this.$refs.table.refresh(false)
          }).catch(_ => {
            this.confirmLoading = false
          })
        }
      })
    },
    handleExport() {
      this.queryParam.ids = this.selectedRowKeys.join(',')
      exportCumsFinance('export_pay_data', this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    },
    feeStatusColor(value) {
      switch (value) {
        case 1:
          return '#faad14'
        case 2:
          return '#1890ff'
        case 3:
          return '#4cd964'
        case 4:
          return '#ff4d4f'
        default:
          return '#4cd964'
      }
    },
    onChange(checkedValues) {
      let count = 0
      this.totalAmount = ''
      const mapItem = new Map()
      for (const item of this.feeOrderList) {
        count += item.feeList.length
        for (let i = 0; i < checkedValues.length; i++) {
          for (let j = 0; j < item.feeList.length; j++) {
            if (checkedValues[i] === item.feeList[j].id) {
              if (mapItem.has(item.feeList[j].currency_name)) {
                 mapItem.set(item.feeList[j].currency_name, item.feeList[j].money + mapItem.get(item.feeList[j].currency_name))
              } else {
                mapItem.set(item.feeList[j].currency_name, item.feeList[j].money)
              }
            }
          }
        }
      }
      for (const key of mapItem.keys()) {
        this.totalAmount += mapItem.get(key).toFixed(2) + key + ','
      }
      this.indeterminate = !!this.checkedList.length && this.checkedList.length < count
      this.checkAll = this.checkedList.length === count
    },
    onCheckAllChange(e) {
      this.checkAll = e.target.checked
      this.indeterminate = false
      this.checkedList = []
      this.totalAmount = ''
      const mapList = new Map()
      if (e.target.checked) {
        this.feeOrderList.forEach(item => {
          for (const e of item.feeList) {
            if (mapList.has(e.currency_name)) {
              mapList.set(e.currency_name, e.money + mapList.get(e.currency_name))
            } else {
              mapList.set(e.currency_name, e.money)
            }
            this.checkedList.push(e.id)
          }
        })
        for (const key of mapList.keys()) {
          this.totalAmount += mapList.get(key).toFixed(2) + key + ','
        }
      }
    },
    // 选择付款对象
    choosePayTarget(record, payBillName) {
      const data = record.fees
      const fees = data.filter(item => item.fee_status >= 3 && item.fee_status !== 4)
      if (fees.length > 0) {
        this.payTarget = fees
        this.payBillName = payBillName
        this.orderData = record
        this.choosePayTargetVisible = true
      } else {
        this.$message.error('当前订单没有提交商务审核费用')
      }
    },
    // 打印付款单
    printPayFeeBill(record) {
      getFmsInfoDetail('business_audit', { id: record.audit_id }).then(v => {
        const result = [];
        v.forEach(item => {
          const feeList = item.pay_fee_list;
          if (typeof feeList !== 'undefined') {
            feeList.forEach(fee => {
              result.push(fee)
            })
          }
        });
        this.printPayBillResult = result;
        let departmentId = 9
        if (record.fee_infos && record.fee_infos.length === 1) {
          departmentId = record.fee_infos[0].department
        }
        this.printPayBillRecord = {
        ...record,
        department: departmentId,
        customer: record.supplier_name,
        currency: 'CNY',
        apply_remark: v[0].remark
      };
        this.printPayBillRecord[`currency_name`] = `CNY`;
        this.printPayBillModel = true;
      });
      // 根据 id 查询开票信息
      getInvoiceDataByAuditId({ id: record.audit_id }).then(v => {
        this.invoiceList = v
      })
    },
    handleDeletePayment(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除付款单?',
        onOk: () => {
          deletePayment({ 'payment_id': record.id }).then(res => {
            if (res) {
              this.$notification['success']({ message: '操作成功' });
            } else {
              this.$notification['error']({ message: '操作失败' });
            }
          }).finally(_ => {
            this.$refs.table.refresh(false);
          })
        }
      })
    },
    handleBindInvoice(record) {
      this.auditId = record.audit_id
      this.invoiceModel = true
    },
    // 提交发票
    handleBindInvoiceSubmit() {
      this.$confirm({
        title: '确认提示',
        content: '确定要关联发票？',
        onOk: () => {
          const data = {
            audit_id: this.auditId,
            invoice_list: this.$refs.bindInvoice.invoice_num
          }
          fmsBizAuditFunc('bind_invoice', data).then((v) => {
            if (v) {
              this.$message.success('关联发票成功')
              this.invoiceModel = false
              this.$refs.table.refresh(false);
              this.$refs.bindInvoice.invoice_num = []
              this.$refs.bindInvoice.initData()
            }
          })
        }
      })
    },
    // 打印弹窗
    billPrint(btnName) {
      document.getElementById(btnName).click()
    },
    getCurrency(s) {
      if (s === undefined || '') {
        return 'hidden'
      } else {
        return 'show'
      }
    },
    getFeeDigit(s) {
      if (s) {
        return s
      } else {
        return ' '
      }
    },
    getFeeAmount(s) {
      if (s) {
        return s
      } else {
        return ''
      }
    },
    moneyFormatter(money) {
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>
