<template>
  <div>
    <a-select
      showSearch
      mode="multiple"
      ref="invoice_number"
      :filter-option="filterOption"
      allowClear
      placeholder="请选择发票号码"
      style="width: 100%"
      v-model="invoice_num"
    >
      <a-select-option v-for="op in invoiceRegOps" :key="op.id"
      >发票号:{{ op.invoice_number }}/金额:{{ op.amount }}/已关联:{{ op.pay_amount }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { filterOption } from '@/utils/util'
import { getCommonNoPage } from '@/api/common'
export default {
  name: 'BindInvoice',
  components: {},
  created() {
    this.initData()
  },
  data() {
    return {
      invoiceRegOps: [],
      invoice_num: []
    }
  },
  methods: {
    filterOption,
    // 发票列表
    initData() {
      getCommonNoPage('invoice_registration', {}).then((v) => {
        this.invoiceRegOps = v
      })
    }
  }
}
</script>

<style scoped>

</style>
